import React from 'react'
import styled from 'styled-components'
import TopBanner from 'images/home/top_banner.jpg'
import Demo from 'images/home/demo.png'

export const Top = () => {
  return (
    <TopArea>
      <Img src={TopBanner} />
      <SubContent>
        <Title>ITの力で勤怠管理業務削減にフルコミット！</Title>
        <SubImg src={Demo} />
      </SubContent>
    </TopArea>
  )
}

const TopArea = styled.div`
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
`
const Img = styled.img`
  width: 100%;
`
const SubContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 10rem;
  z-index: 50;
`
const Title = styled.div`
  font-size: 3rem;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bold;
  color: #000000;
  @media screen and (max-width: 1000px) {
    font-size: 2.5rem;
  }
`
const SubImg = styled.img`
  @media screen and (max-width: 1000px) {
    width: 70%;
  }
`

export default Top
