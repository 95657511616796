import React from 'react'
import styled from 'styled-components'
import functions from 'images/home/feature/functions.jpg'

export const Feature = () => {
  return (
    <FeatureArea>
      <ContentArea>
        <Title>FUNCTIONS</Title>
        <List>
          <Item>
            <SubTitle>①従業員管理機能</SubTitle>
            <Content>
              所属部署や雇用形態といった基本情報とともに全従業員を一覧化。
            </Content>
          </Item>
          <Item>
            <SubTitle>②出退勤管理機能</SubTitle>
            <Content>
              各従業員は各自のアカウントからシンプルな打刻入力で、出退勤を登録できます。
            </Content>
          </Item>
          <Item>
            <SubTitle>③各種申請管理機能</SubTitle>
            <Content>
              休暇申請、休日出勤申請といった各種申請から管理者承認までをシステム内でワンストップ実行が可能です。
            </Content>
          </Item>
          <Item>
            <SubTitle>④CSVダウンロード機能</SubTitle>
            <Content>
              指定した条件での勤怠情報データがCSV形式で出力可能です。
            </Content>
          </Item>
        </List>
      </ContentArea>
      <ImgArea>
        <Img src={functions} />
      </ImgArea>
    </FeatureArea>
  )
}

const FeatureArea = styled.div`
  background-color: #ecf0f2;
  padding: 1rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
const ContentArea = styled.div`
  width: 80%;
  margin: 0 auto;
  grid-column: span 1 / span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Title = styled.h2`
  color: #27adc9;
  font-size: 2rem;
`
const ImgArea = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  justify-content: center;
`
const SubTitle = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  color: #000000;
`
const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`
const Item = styled.div`
`
const Content = styled.div`
  font-size: 1.3rem;
`
const Img = styled.img`
  width: 80%;
  object-fit: scale-down;
`

export default Feature
