import React from "react";
import styled from "styled-components";

export interface SubmitButtonProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const SubmitButton: React.FC<SubmitButtonProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  color: #ffffff;
  text-decoration: none;
  background: linear-gradient(to right,#ffffff 50%, #27adc9 50%);
  background-size: 200% 100.5%;
  background-position: right bottom;
  transition: all .3s ease-out;
  border: 0.1rem solid #27adc9;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  &:hover {
    background-position: left bottom;
    color: #27adc9;
  }
  &:disabled {
    color: #000000;
    background: #9BA4B4;
    border: 2px solid #696969;
    pointer-events: none;
  }
`;

export default SubmitButton
