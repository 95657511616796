import React from 'react'
import styled from 'styled-components'

export const LoginButton = () => {
  return (
    <Login href='/companies/sign_in'>
      サインイン
    </Login>
  )
}

const Login = styled.a`
  color: #27adc9;
  text-decoration: none;
  background: linear-gradient(to right,#27adc9 50%,#fff 50%);
  background-size: 200% 100.5%;
  background-position: right bottom;
  transition: all .3s ease-out;
  border: 0.1rem solid #27adc9;
  padding: 0.3rem 1rem;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  letter-spacing: 0.1rem;
  &:hover {
    background-position: left bottom;
    color: #ffffff;
  }
`

export default LoginButton
