import React from 'react';
import styled from 'styled-components'
import Timestamp from '../../organisms/employees/Timestamp'

export const Index: React.FC = () => {
  return (
    <IndexArea>
      <Timestamp />
    </IndexArea>
  )
}

const IndexArea = styled.div`
  border: 1px solid #000000;
`

export default Index
