import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import DepartmentForm from '../../../organisms/management/departments/DepartmentForm'
import ButtonSub from '../../../atoms/share/button/ButtonSub';

export interface Props {
  department?: {
    id: number
    name: string
  }
}

export const Edit: React.FC<Props> = ({ department }) => {
  return (
    <EditArea>
      <TitleArea>
        <Title value="部署編集" />
      </TitleArea>
      <DepartmentForm department={department} />
      <ButtonArea>
        <ButtonSub path={`/management/staff`} text='戻る' />
      </ButtonArea>
    </EditArea>
  )
}

export const EditArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  padding: 1rem 2rem 1rem 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`
const ButtonArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export default Edit
