import React from "react";
import styled from "styled-components";

export interface ButtonSecondaryProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type="submit" onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  color: #ffffff;
  background: linear-gradient(to right,#ffffff 50%,#27adc9 50%);
  background-size: 200% 100.5%;
  background-position: right bottom;
  transition: all .3s ease-out;
  border: 1px solid #27adc9;
  box-sizing: border-box;
  text-align: center;
  cursor: pointer;
  padding: 5px 15px;
  width: 160px;
  font-size: 18px;
  :disabled {
    border: 2px solid #696969;
    background: #9BA4B4;
    pointer-events: none;
  }
  &:hover {
    color: #27adc9;
    background-position: left bottom;
  }
`;

export default ButtonSecondary
