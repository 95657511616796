import React from 'react';
import styled from 'styled-components';

export interface ButtonRedProps {
  onClick: any;
  text: string;
  disabled?: boolean;
}

export const ButtonRed: React.FC<ButtonRedProps> = ({ onClick, text, disabled = false }) => {
  return <BaseButton type='submit' onClick={onClick} disabled={disabled} >{text}</BaseButton>;
};

const BaseButton = styled.button`
  background:#dc2626;
  background: linear-gradient(to right,#ffffff 50%,#dc2626 50%);
  background-size: 200% 100.5%;
  background-position: right bottom;
  transition: all .3s ease-out;
  border: 1px solid #f01b1b;
  box-sizing: border-box;
  color: #ffffff;
  padding: 5px 15px;
  text-decoration: none;
  width: fit-content;
  min-width: 5rem;
  white-space: nowrap;
  cursor: pointer;
  :disabled {
    background: #9BA4B4;
    pointer-events: none;
  }
  &:hover {
    background-position: left bottom;
    color: #dc2626;
  }
`;

export default ButtonRed
