import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Title from '../../atoms/share/Title'
import SectionTitle from '../../atoms/employees/SectionTitle'
import SubmitButton from '../../atoms/SubmitButton'
import TextForm from '../../atoms/TextForm'
import { fetchTimestamp, createTimestamp } from '../../../api/timestamp'

// 昨日のtimestampも取得してエラーないか確認必要

const set2 = (num) => {
  let ret;
  if (num < 10) { ret = "0" + num; }
  else { ret = num; }
  return ret;
}

const showClock = () => {
  const nowTime = new Date(); //現在日時
  const nowHour = set2(nowTime.getHours()); //時間
  const nowMin = set2(nowTime.getMinutes()); //分
  const nowSec = set2(nowTime.getSeconds()); //秒
  const msg = nowHour + ":" + nowMin + ":" + nowSec
  return msg
}

interface timestampProps {
  date: string
  punch_in_at: string
  punch_out_at: string
  is_holiday: boolean
  is_paid_leave: boolean
}

export const Timestamp: React.FC = () => {
  const [realTime, setRealTime] = useState("")
  const [timestamp, setTimestamp] = useState<timestampProps>()
  const [status, setStatus] = useState("")
  const [loading, setLoading] = useState(false)
  const [note, setNote] = useState("")

  useEffect(() => {
    setInterval(() => {
      setRealTime(showClock())
    }, 1000);

    fetchTimestamp()
      .then((res) => {
        // timestamp不要であれば削除する
        setTimestamp(res.data)
        setNote(res.data.note)
        changeStatus(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
        setStatus('未出勤')
      })
  }, []);

  const changeStatus = (timestamp: timestampProps) => {
    if (timestamp.punch_in_at === null) {
      setStatus('未出勤')
    } else if (timestamp.punch_in_at !== null && timestamp.punch_out_at === null) {
      setStatus('出勤中')
    } else if (timestamp.punch_out_at !== null) {
      setStatus('退勤済')
    } else {
      setStatus('未出勤')
    }
  }

  const stamp = () => {
    setLoading(true)
    createTimestamp(note)
      .then((res) => {
        setTimestamp(res.data)
        changeStatus(res.data)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  return (
    <TimestampArea>
      <Title value='打刻' />
      <Content>
        <Section>
          <SectionTitle>{status}</SectionTitle>
          <Time>{realTime}</Time>
          <TextForm type="text" placeholder="退勤時備考・メモ" value={note} onChange={(e) => setNote(e.target.value)} />
          <TimestampButton>
            <SubmitButton text='打刻' onClick={stamp} disabled={loading || status === '退勤済'} />
          </TimestampButton>
        </Section>
      </Content>
    </TimestampArea>
  )
}

const TimestampArea = styled.div`
  background: #ecf0f2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Section = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Time = styled.div`
  font-size: 100px;
  font-weight: bold;
  letter-spacing: 10px;
  margin: 90px 0;
  text-align: center;
`
const TimestampButton = styled.div`
  width: 100%;
  margin-top: 3rem;
  text-align: center;
`

export default Timestamp
