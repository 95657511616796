import React from 'react';
import styled from 'styled-components'

export const LogoManagement = () => {
  return (
    <LogoArea href="/management">
      {/* <svg xmlns="http://www.w3.org/2000/svg" width="11.833" height="30" viewBox="0 0 11.833 46.876">
        <g id="グループ_4" data-name="グループ 4" transform="translate(-934 -1308.955)">
          <path id="パス_78" data-name="パス 78" d="M21.114,38.969a2.451,2.451,0,0,1-4.529,1.3L10,33.682a2.451,2.451,0,1,1,3.468-3.465l2.745,2.746v-18.5a2.451,2.451,0,1,1,4.9,0V38.967Z" transform="translate(924.719 1296.945)" fill="#9ba4b4" />
          <path id="パス_77" data-name="パス 77" d="M19.476,8.441A2.451,2.451,0,0,1,24,7.141l6.587,6.586a2.451,2.451,0,0,1-3.468,3.465l-2.745-2.746v18.5a2.451,2.451,0,1,1-4.9,0V8.441Z" transform="translate(914.524 1320.432)" fill="#14274e" />
        </g>
      </svg> */}
      <Title>勤怠管理システム</Title>
    </LogoArea>
  )
}

const LogoArea = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`
const Title = styled.h1`
  font-size: 30px;
  margin: 0;
  padding-left: 24px;
  background: linear-gradient(150deg, rgba(0, 0, 0, 1) 0%, rgba(85, 85, 85, 1) 35%, rgba(220, 220, 220, 1) 50%, rgba(0, 0, 0, 1) 80%);
  background-clip: text;
  color: transparent;
  font-family: 'Pirulen', sans-serif, 'Noto Sans JP';
`

export default LogoManagement
