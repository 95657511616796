import React from 'react';
import styled from 'styled-components'
import Title from '../../../atoms/share/Title'
import DepartmentForm from '../../../organisms/management/departments/DepartmentForm'

export const New = () => {
  return (
    <NewArea>
      <TitleArea>
        <Title value="部署 新規登録" />
      </TitleArea>
      <DepartmentForm />
    </NewArea>
  )
}

export const NewArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin: 0 auto;
  padding: 1rem 2rem 1rem 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`

export default New
