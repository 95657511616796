import React from 'react'
import styled from 'styled-components'
import timecard from 'images/home/summary/timecard.jpg'

export const Summary = () => {
  return (
    <SummaryArea>
      <ImgArea>
        <Img src={timecard} />
      </ImgArea>
      <TextArea>
        <Title>WHAT IS 勤怠管理システム</Title>
        <Content>
          必要最低限の入力で社内の勤怠管理を一元管理。<br />
          毎月の手間だった集計作業を削減することで、業務の効率化を推進します。
        </Content>
      </TextArea>
    </SummaryArea>
  )
}

const SummaryArea = styled.div`
  background-color: #ecf0f2;
  padding: 1rem 0;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
`
const ImgArea = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  justify-content: center;
`
const TextArea = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const Title = styled.h2`
  color: #27adc9;
  font-size: 2rem;
`
const Content = styled.div`
  width: fit-content;
  color: #000000;
  font-size: 1.3rem;
`
const Img = styled.img`
  width: 80%;
`

export default Summary
