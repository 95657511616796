import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import Select from 'react-select'
import Title from '../../../atoms/share/Title'
import TextForm from '../../../atoms/TextForm'
import SubmitButton from '../../../atoms/SubmitButton'
import {updateEmployee} from '../../../../api/employee'
import { fetchDepartments } from '../../../../api/department'
import { fetchEmploymentStatuses } from '../../../../api/employment_status'
import {EditableEmployeeParams} from "../../../../types/employee";
import ButtonSub from '../../../atoms/share/button/ButtonSub';

interface EditFormProps {
  staff: EditableEmployeeParams;
}

export const EditForm = (props: EditFormProps) => {
  type Properties = {
    id: string, name: string
  }
  const [lastName, setLastName] = useState(props.staff.last_name)
  const [firstName, setFirstName] = useState(props.staff.first_name)
  const [email, setEmail] = useState(props.staff.email)
  const [password, setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [updateComplete, setUpdateComplete] = useState(false)
  const [department, setDepartment] = useState<Properties>();
  const [departments, setDepartments] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState<Properties>();
  const [employmentStatuses, setEmploymentStatuses] = useState([]);

  useEffect(() => {
    fetchDepartments()
      .then((res) => {
        setDepartments(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
    fetchEmploymentStatuses()
      .then((res) => {
        setEmploymentStatuses(res.data)
      })
      .catch(() => {
        window.alert('通信に失敗しました')
      });
    setPropsValuesToState();
  }, []);

  const setPropsValuesToState = () => {
    if (props.staff.department_id) {
      setDepartment({id: String(props.staff.department_id), name: props.staff.department_name})
    }
    if (props.staff.employment_status_id) {
      setEmploymentStatus({id: String(props.staff.employment_status_id), name: props.staff.employment_status_name})
    }
  }

  const handleSubmit = () => {
    setLoading(true)
    updateEmployee(props.staff.id, {last_name: lastName, first_name: firstName, email, password, department_id: Number(department?.id), employment_status_id: Number(employmentStatus?.id)})
      .then(() => {
        setLoading(false)
        setUpdateComplete(true)
      })
      .catch(() => {
        setLoading(false)
        console.log('通信に失敗しました')
        window.alert('通信に失敗しました')
      })
  }

  const noticeComplete = () => {
    if (updateComplete) {
      return (
        <p>更新が完了しました</p>
      )
    }
  }

  return (
    <EditFormArea>
      <TitleArea>
        <Title value="従業員 編集" />
      </TitleArea>
      <Form>
        <Notification>
          {noticeComplete()}
        </Notification>
        <Item>
          <Label>姓</Label>
          <TextForm type="text" placeholder="山田" value={lastName} onChange={(e) => setLastName(e.target.value)} />
        </Item>
        <Item>
          <Label>名</Label>
          <TextForm type="text" placeholder="太郎" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
        </Item>
        <Item>
          <Label>部署</Label>
          <SelectBox>
            <Select
              value={department}
              onChange={setDepartment}
              options={departments}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
              styles={SelectArea}
              placeholder="選択してください"
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>雇用形態</Label>
          <SelectBox>
            <Select
              value={employmentStatus}
              onChange={setEmploymentStatus}
              options={employmentStatuses}
              getOptionLabel={(option: Properties) => option.name}
              getOptionValue={(option: Properties) => option.id}
              styles={SelectArea}
              placeholder="選択してください"
            />
          </SelectBox>
        </Item>
        <Item>
          <Label>メールアドレス</Label>
          <TextForm type="email" placeholder="mail@example.com" value={email} onChange={(e) => setEmail(e.target.value)} />
        </Item>
        <Item>
          <Label>パスワード</Label>
          <TextForm type="password" placeholder="" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Item>
        <RegistrationButton>
          <SubmitButton
            text="更新する"
            onClick={handleSubmit}
            disabled={loading || !lastName || !firstName || !email}
          />
          <ButtonArea>
            <ButtonSub path={`/management/staff`} text='戻る' />
          </ButtonArea>
        </RegistrationButton>
      </Form>
    </EditFormArea>
  )
}

const EditFormArea = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding: 1rem 2rem 1rem 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 1rem;
`
const TitleArea = styled.div`
  width: 100%;
  text-align: center;
`
const Form = styled.form`
  font-size: 16px;
`
const Notification = styled.div`
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #06d6a0;
`
const Item = styled.div`
  display: flex;
  align-items: center;
  line-height: 43px;
  list-style: none;
  margin-bottom: 24px;
  column-gap: 3rem;
`
const Label = styled.label`
  color: #000000;
  text-align: right;
  width: 10rem;
`
const SelectBox = styled.div`
  display: flex;
  align-items: center;
`
const SelectArea = {
  control: (provided) => ({
    ...provided,
    width: '15rem',
  }),
};
const RegistrationButton = styled.div`
  width: 100%;
  text-align: center;
  margin: 75px 0 0 0;
`
const ButtonArea = styled.div`
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
`
export default EditForm
